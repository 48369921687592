@keyframes rainbow-animation {
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}

@keyframes fade-in-scale {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.rainbow {
  background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
  background-clip: text;
  color: transparent;
  animation: rainbow-animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

.fade-in-scale {
  animation: fade-in-scale 100ms ease-in-out;
}
